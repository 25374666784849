var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "animated fadeIn" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [_vm._v("Headings")]),
        _c("div", { staticClass: "card-body" }, [
          _c("p", [
            _vm._v(
              "Documentation and examples for Bootstrap typography, including global settings, headings, body text, lists, and more."
            ),
          ]),
          _c("table", { staticClass: "table" }, [
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Heading")]),
                _c("th", [_vm._v("Example")]),
              ]),
            ]),
            _c("tbody", [
              _c("tr", [
                _c("td", [
                  _c("p", [
                    _c("code", { staticClass: "highlighter-rouge" }, [
                      _vm._v("<h1></h1>"),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "h1" }, [
                    _vm._v("h1. Bootstrap heading"),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _c("p", [
                    _c("code", { staticClass: "highlighter-rouge" }, [
                      _vm._v("<h2></h2>"),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "h2" }, [
                    _vm._v("h2. Bootstrap heading"),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _c("p", [
                    _c("code", { staticClass: "highlighter-rouge" }, [
                      _vm._v("<h3></h3>"),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "h3" }, [
                    _vm._v("h3. Bootstrap heading"),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _c("p", [
                    _c("code", { staticClass: "highlighter-rouge" }, [
                      _vm._v("<h4></h4>"),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "h4" }, [
                    _vm._v("h4. Bootstrap heading"),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _c("p", [
                    _c("code", { staticClass: "highlighter-rouge" }, [
                      _vm._v("<h5></h5>"),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "h5" }, [
                    _vm._v("h5. Bootstrap heading"),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("td", [
                  _c("p", [
                    _c("code", { staticClass: "highlighter-rouge" }, [
                      _vm._v("<h6></h6>"),
                    ]),
                  ]),
                ]),
                _c("td", [
                  _c("span", { staticClass: "h6" }, [
                    _vm._v("h6. Bootstrap heading"),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [_vm._v("Headings")]),
        _c("div", { staticClass: "card-body" }, [
          _c("p", [
            _c("code", { staticClass: "highlighter-rouge" }, [_vm._v(".h1")]),
            _vm._v(" through "),
            _c("code", { staticClass: "highlighter-rouge" }, [_vm._v(".h6")]),
            _vm._v(
              " classes are also available, for when you want\n\t\t\t\tto match the font styling of a heading but cannot use the associated HTML element.\n\t\t\t"
            ),
          ]),
          _c("div", { staticClass: "bd-example" }, [
            _c("p", { staticClass: "h1" }, [_vm._v("h1. Bootstrap heading")]),
            _c("p", { staticClass: "h2" }, [_vm._v("h2. Bootstrap heading")]),
            _c("p", { staticClass: "h3" }, [_vm._v("h3. Bootstrap heading")]),
            _c("p", { staticClass: "h4" }, [_vm._v("h4. Bootstrap heading")]),
            _c("p", { staticClass: "h5" }, [_vm._v("h5. Bootstrap heading")]),
            _c("p", { staticClass: "h6" }, [_vm._v("h6. Bootstrap heading")]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [_vm._v("Display headings")]),
        _c("div", { staticClass: "card-body" }, [
          _c("p", [
            _vm._v(
              "\n\t\t\t\tTraditional heading elements are designed to work best in the meat of your page content. When you need a heading to stand out, consider\n\t\t\t\tusing a "
            ),
            _c("strong", [_vm._v("display heading")]),
            _vm._v(
              "—a larger, slightly more opinionated heading style.\n\t\t\t"
            ),
          ]),
          _c("div", { staticClass: "bd-example bd-example-type" }, [
            _c("table", { staticClass: "table" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", [
                    _c("span", { staticClass: "display-1" }, [
                      _vm._v("Display 1"),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("span", { staticClass: "display-2" }, [
                      _vm._v("Display 2"),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("span", { staticClass: "display-3" }, [
                      _vm._v("Display 3"),
                    ]),
                  ]),
                ]),
                _c("tr", [
                  _c("td", [
                    _c("span", { staticClass: "display-4" }, [
                      _vm._v("Display 4"),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("Inline text elements"),
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("p", [
            _vm._v(
              "\n\t\t\t\tTraditional heading elements are designed to work best in the meat of your page content. When you need a heading to stand out, consider\n\t\t\t\tusing a "
            ),
            _c("strong", [_vm._v("display heading")]),
            _vm._v(
              "—a larger, slightly more opinionated heading style.\n\t\t\t"
            ),
          ]),
          _c("div", { staticClass: "bd-example" }, [
            _c("p", [
              _vm._v("You can use the mark tag to "),
              _c("mark", [_vm._v("highlight")]),
              _vm._v(" text."),
            ]),
            _c("p", [
              _c("del", [
                _vm._v(
                  "This line of text is meant to be treated as deleted text."
                ),
              ]),
            ]),
            _c("p", [
              _c("s", [
                _vm._v(
                  "This line of text is meant to be treated as no longer accurate."
                ),
              ]),
            ]),
            _c("p", [
              _c("ins", [
                _vm._v(
                  "This line of text is meant to be treated as an addition to the document."
                ),
              ]),
            ]),
            _c("p", [
              _c("u", [_vm._v("This line of text will render as underlined")]),
            ]),
            _c("p", [
              _c("small", [
                _vm._v(
                  "This line of text is meant to be treated as fine print."
                ),
              ]),
            ]),
            _c("p", [
              _c("strong", [_vm._v("This line rendered as bold text.")]),
            ]),
            _c("p", [
              _c("em", [_vm._v("This line rendered as italicized text.")]),
            ]),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "card-header" }, [
          _vm._v("Description list alignment"),
        ]),
        _c("div", { staticClass: "card-body" }, [
          _c("p", [
            _vm._v(
              "\n\t\t\t\tAlign terms and descriptions horizontally by using our grid system’s predefined classes (or semantic mixins). For longer terms, you can\n\t\t\t\toptionally add a "
            ),
            _c("code", { staticClass: "highlighter-rouge" }, [
              _vm._v(".text-truncate"),
            ]),
            _vm._v(" class to truncate the text with an ellipsis.\n\t\t\t"),
          ]),
          _c("div", { staticClass: "bd-example" }, [
            _c("dl", { staticClass: "row" }, [
              _c("dt", { staticClass: "col-sm-3" }, [
                _vm._v("Description lists"),
              ]),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v("A description list is perfect for defining terms."),
              ]),
              _c("dt", { staticClass: "col-sm-3" }, [_vm._v("Euismod")]),
              _c("dd", { staticClass: "col-sm-9" }, [
                _c("p", [
                  _vm._v(
                    "Vestibulum id ligula porta felis euismod semper eget lacinia odio sem nec elit."
                  ),
                ]),
                _c("p", [
                  _vm._v("Donec id elit non mi porta gravida at eget metus."),
                ]),
              ]),
              _c("dt", { staticClass: "col-sm-3" }, [
                _vm._v("Malesuada porta"),
              ]),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v("Etiam porta sem malesuada magna mollis euismod."),
              ]),
              _c("dt", { staticClass: "col-sm-3 text-truncate" }, [
                _vm._v("Truncated term is truncated"),
              ]),
              _c("dd", { staticClass: "col-sm-9" }, [
                _vm._v(
                  "\n\t\t\t\t\t\tFusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus.\n\t\t\t\t\t"
                ),
              ]),
              _c("dt", { staticClass: "col-sm-3" }, [_vm._v("Nesting")]),
              _c("dd", { staticClass: "col-sm-9" }, [
                _c("dl", { staticClass: "row" }, [
                  _c("dt", { staticClass: "col-sm-4" }, [
                    _vm._v("Nested definition list"),
                  ]),
                  _c("dd", { staticClass: "col-sm-8" }, [
                    _vm._v(
                      "Aenean posuere, tortor sed cursus feugiat, nunc augue blandit nunc."
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }